




























































@use "@material/checkbox";
@use "@material/form-field";

@include checkbox.core-styles;
@include form-field.core-styles;

$unmarked-stroke-color: #c7c8cc;
$generate-keyframes: false;
$marked-stroke-color: #FFFFFF;
$marked-fill-color: #FFFFFF;

.mdc-checkbox {
  @include checkbox.container-colors($unmarked-stroke-color, $marked-stroke-color);
  @include checkbox.ink-color(white);
  @include checkbox.focus-indicator-color(#00ADD9);
  padding-right: 6px;
  --mdc-ripple-left: 7px !important;
}

.mdc-form-field label {
  font-weight: 400;
  color: #000010;
  padding-left: 0;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.chk-lbl {
  color: var(--color-text-primary) !important;
  line-height: 40px;
  padding-bottom: 2px;
}
